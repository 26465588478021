<template>
    <content-wrapper>
        <h1 class="text-h4">{{ $t('login.stages.page-header-text') }}</h1>

        <v-form ref="form" @submit.prevent>
            <v-text-field
                v-model="username"
                autocomplete="off"
                label="Username"
                :rules="[v => !!v || $t('validation.required', { label: $t('common.username') })]"
                :autofocus="true"
            />

            <v-text-field-password v-model="password" mode="current" />

            <div class="mt-4">
                <v-btn id="btn-next" type="submit" class="mr-3" color="secondary" depressed :loading="submitInProgress" :disabled="submitInProgress" @click="login">{{ $t('login.stages.enter-user-id.button-next') }}</v-btn>
            </div>
        </v-form>
    </content-wrapper>
</template>

<script>
    import VTextFieldPassword from '@/components/base/VTextFieldPassword';

    export default {
        name: 'Login',

        components: {
            VTextFieldPassword
        },

        data() {
            return {
                username: '',
                password: '',
                submitInProgress: false
            };
        },

        computed: {
            isValid() {
                // @ts-ignore
                return this.$refs.form.validate();
            }
        },

        methods: {
            async login() {
                if (this.isValid) {
                    try {
                        const url = `${process.env.VUE_APP_API_ENDPOINT}/users/${this.username}`;
                        const res = await fetch(url);
                        const data = await res.json();

                        const correctPassword = this.password === data.password;

                        if (correctPassword) {
                            localStorage.setItem('isAuthenticated', 'true');
                            this.$root.isAuthenticated = true;
                            this.$router.push({ name: 'LandingPage' });
                        } else {
                            this.username = '';
                            this.password = '';

                            this.$notification.error({
                                message: this.$t('login.errors.generic')
                            });
                        }
                    } catch (error) {
                        console.error(error);
                    }
                }
            }
        }
    };
</script>

<style scoped>

</style>
