
    import Vue from 'vue';
    import { SessionManager } from '@forgerock/javascript-sdk';

    export default Vue.extend({
        name: 'AppHeader',

        data() {
            return {
                activeTab: {} as Record<string, any>,
                showNav: false,
                menuItems: [
                    { title: 'Dashboard', route: 'Dashboard' },
                    { title: 'Settings', route: 'Settings' },
                    { title: 'Logout', route: 'Logout' }
                ],
                navItems: [
                    {
                        title: 'Checking & Savings',
                        icon: 'mdi-piggy-bank-outline',
                        categories: [
                            {
                                title: 'Offerings',
                                items: [
                                    { title: 'Checking', route: 'Register' },
                                    { title: 'Savings', route: 'Register' }
                                ]
                            }
                        ]
                    },
                    {
                        title: 'Investing & Retirement',
                        icon: 'mdi-cash',
                        categories: [
                            {
                                title: 'Offerings',
                                items: [
                                    { title: 'Traditional IRA', route: 'Register' },
                                    { title: 'Roth IRA', route: 'Register' },
                                    { title: 'Bonds', route: 'Register' }
                                ]
                            },
                            {
                                title: 'Tools',
                                items: [
                                    { title: 'Retirement Calculator', route: 'Register' }
                                ]
                            },
                            {
                                title: 'Guides',
                                items: [
                                    { title: 'Traditional vs. Roth', route: 'Register' }
                                ]
                            }
                        ]
                    },
                    {
                        title: 'Home Loan',
                        icon: 'mdi-home-outline',
                        categories: [
                            {
                                title: 'Offerings',
                                items: [
                                    { title: 'Home Purchase', route: 'HomeLoan' },
                                    { title: 'Mortgage Refinancing', route: 'Register' }
                                ]
                            },
                            {
                                title: 'Guides',
                                items: [
                                    { title: 'How much do I qualify for?', route: 'Register' },
                                    { title: 'Types of loans', route: 'Register' }
                                ]
                            }
                        ]
                    }
                ]
            };
        },

        computed: {
            headerHeight() {
                return (this.$root as any).config.headerHeight;
            }
        },

        methods: {
            closeMenu() {
                this.activeTab = {};
                this.showNav = false;
            },

            onClickGetStarted() {
                this.$router.push({ name: 'Register' });
                this.closeMenu();
            },

            toggleMenu(tab: Record<string, any>) {
                if (this.activeTab.title !== tab.title) {
                    this.activeTab = tab;
                    this.showNav = true;
                } else {
                    this.closeMenu();
                }
            },

            async login() {
                if (document.cookie.includes('oauth=true')) {
                    await SessionManager.logout();
                    await this.$util.oauth.login();
                } else {
                    this.$router.push({ name: 'Login' });
                }
            }
        }
    });
