import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import { notificationStore } from '@/services/notification';
import Admin from '@/views/Admin.vue';
import Dashboard from '@/views/Dashboard.vue';
import LandingPage from '@/views/LandingPage.vue';
import Login from '@/views/Login.vue';
import Logout from '@/views/Logout.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '*',
        redirect: { name: 'Login' }
    },
    {
        path: '/',
        name: 'LandingPage',
        component: LandingPage
    },
    {
        path: '/admin',
        name: 'Admin',
        component: Admin
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            authRequired: true
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        props: (route: Route) => ({
            failureMessage: route.query.failure,
            internalRedirect: route.query['internal-redirect']
        })
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout
    },
    {
        path: '/settings',
        name: 'Settings',
        beforeEnter() {
            window.location.href = process.env.VUE_APP_FEDERATION_URL + '/profile';
        }
    }
];

const router = new VueRouter({
    mode: 'history',
    routes,
    base: '/'
});

// eslint-disable-next-line space-before-function-paren
router.beforeEach(async (to, from, next) => {
    // @ts-ignore
    await router.app.$options.data().appInitializedPromise;

    notificationStore.clearAll(); // Clear all notifications (error, warning, success, and info) by default on route change

    // Auth Required? If so, force login then redirect to the original destination after successful login.
    // @ts-ignore
    if (to.meta.authRequired && !router.app.isAuthenticated) {
        next({ name: 'Login', query: { 'internal-redirect': to.fullPath } });
    } else {
        next();
    }
});

export default router;
