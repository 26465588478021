import 'whatwg-fetch';
import './router/class-component-router-hooks'; // Order matters. This import must appear before the Vue import.
import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import 'vue-class-component/hooks'; // import standard hooks type to enable auto-complete for Vue Class Components
import i18n from './i18n';
import vuetify from '@/plugins/vuetify';
import AxiosPlugin from '@/plugins/axios';
import UtilsPlugin from '@/plugins/utils';
import { Service } from '@/services/service';
import { Config } from '@forgerock/javascript-sdk';

import VTextFieldMasked from '@/components/base/VTextFieldMasked.vue';
import VTextFieldPassword from '@/components/base/VTextFieldPassword.vue';
import ContentWrapper from '@/components/base/ContentWrapper.vue';

Vue.config.productionTip = false;
Vue.use(AxiosPlugin);
Vue.use(UtilsPlugin);

// Globally register base components (i.e. all Vue Components in src/components/base). This has to be done before
// 'new Vue({})'. If we start adding a lot of base components, we can automatically register them as follows:
// https://vuejs.org/v2/guide/components-registration.html#Automatic-Global-Registration-of-Base-Components
//
// NOTE: When the components are automatically registered, though, Intellij will complain about unknown tag names in HTML.
Vue.component('VTextFieldMasked', VTextFieldMasked);
Vue.component('VTextFieldPassword', VTextFieldPassword);
Vue.component('ContentWrapper', ContentWrapper);

let resolveAppInitializedPromise: () => void;
// @ts-ignore
const appInitializedPromise = new Promise(resolve => { resolveAppInitializedPromise = resolve; });

const vue = new Vue({
    router,
    vuetify,
    i18n,

    data: () => ({
        appInitializedPromise: appInitializedPromise,
        amServerInfo: null as any | null,
        checkedUserType: false,
        config: {
            amDeployContext: process.env.VUE_APP_AM_DEPLOY_CONTEXT || 'auth',
            amHost: process.env.VUE_APP_AM_HOST,
            amRealmPath: null as string | null,
            disableSuccessUrlRedirect: process.env.VUE_APP_DISABLE_SUCCESS_URL_REDIRECT === 'true', // Toggle with Vue dev tool (used for testing purposes)
            headerHeight: 78, // If changed, also change the associated SASS var, '$header-height', in @/styles/variables.scss
            requestTimeout: process.env.VUE_APP_REQUEST_TIMEOUT !== undefined ? parseInt(process.env.VUE_APP_REQUEST_TIMEOUT, 10) : 10000
        },
        isAuthenticated: false, // TODO - This is a little too simplistic. Doesn't take into account session/token expiration.
        queryParams: {} as Record<string, string | string[]>,
        sdkConfig: {},
        selfServiceRedirectUrls: {} as Record<string, any>
    }),

    created: async function() {
        // Init QueryParams object
        this.initQueryParams();

        // Init Axios defaults
        this.$axios.defaults.timeout = this.config.requestTimeout;
        this.$axios.defaults.baseURL = `${this.config.amHost}/${this.config.amDeployContext}/json`;

        try {
            await this.initAppData();
            resolveAppInitializedPromise();
        } catch (error) {
            this.$notification.error({
                title: this.$t('errors.app-init.title') as string,
                message: this.$t('errors.app-init.message') as string
            }, error);
        }
    },

    methods: {
        initQueryParams: function() {
            if (window.location.search) {
                const queryParams = new URLSearchParams(window.location.search);

                for (const queryParamName of queryParams.keys()) {
                    let queryParamValue: string | string[] = queryParams.getAll(queryParamName);
                    if (queryParamValue.length === 1) {
                        queryParamValue = queryParamValue[0];
                    }
                    this.queryParams[queryParamName] = queryParamValue;
                }
            }
        },

        initAppData: async function() {
            // Init AM Server Information
            const serverInfoResponse = await this.$axios.get('/serverinfo/*', {
                headers: {
                    'Accept-API-Version': 'resource=1.1'
                }
            });

            this.amServerInfo = serverInfoResponse.data;
            this.config.amRealmPath = serverInfoResponse.data.realm;

            // Check if isAuthenticated has been set in localStorage
            // This is just for logging in with OAuth disabled
            if (localStorage.getItem('isAuthenticated') === 'true' && !document.cookie.includes('oauth=true')) {
                this.isAuthenticated = true;
            }

            // If the server returned an authorization code, attempt to exchange it for an access token
            if (this.queryParams.code) {
                const idToken = await this.$util.oauth.getIdToken(this.queryParams.code as string, this.queryParams.state as string);
                const isUserTypeMember = this.$util.oauth.isUserTypeMember(idToken);

                if (isUserTypeMember) {
                    this.isAuthenticated = true;
                }

                this.checkedUserType = true;
            }

            if (!this.checkedUserType) {
                try {
                    // Init session info
                    const authInfoPromise = this.$axios.post('/sessions?_action=getSessionInfo', undefined, {
                        headers: {
                            'Accept-API-Version': 'protocol=1.0,resource=2.0'
                        },
                        withCredentials: true
                    });

                    await authInfoPromise;
                    await this.$util.oauth.login();
                } catch (error: any) {
                    this.checkedUserType = true;
                    if (error.isAxiosError && error.response.status === 401) {
                        console.debug('Received unauthorized (401) response when attempting to retrieve session information. This is normal if the session/token is invalid or expired.', error);
                    } else {
                        throw error;
                    }
                }
            }

            // Setup ForgeRock JavaScript SDK Config
            this.sdkConfig = Object.freeze({
                realmPath: this.config.amRealmPath,
                serverConfig: {
                    baseUrl: `${this.config.amHost}/${this.config.amDeployContext}/`,
                    timeout: this.config.requestTimeout
                },
                tree: undefined
            });

            Config.set(this.sdkConfig);
        }
    },

    render(h) {
        if (this.checkedUserType) {
            return h(App);
        } else {
            return null;
        }
    }
}).$mount('#app');

// Allow all services that extend Service to access the root Vue instance
Service.$vue = vue;
