<template>
    <div>
        <div id="dashboard-grid" class="my-6">

            <div id="left-col">
                <!--Account details-->
                <div class="rounded white shadow pa-8">
                    <h1 class="secondary--text mb-6">Welcome back, John</h1>

                    <template v-if="!selectedAccount">
                        <h2 class="secondary--text mb-6">Details</h2>
                        Select an account to show details
                    </template>

                    <template v-else>
                        <div class="d-flex justify-space-between">
                            <h2 class="secondary--text">{{ selectedAccount.nickname }}</h2>
                            <span class="text-h3" :class="amountClass(selectedAccount.balance)">${{ selectedAccount.balance }}</span>
                        </div>
                        <div class="text-h5 grey--text text--lighten-1 font-weight-regular">xxxx-xxxx-xxxx-{{ selectedAccount.id.slice(-4) }}</div>
                    </template>
                </div>

                <!--Transactions-->
                <div
                    v-if="selectedAccount"
                    class="rounded white shadow pa-8"
                >
                    <div class="d-flex justify-space-between">
                        <h2 class="secondary--text">Transactions</h2>

                        <v-text-field
                            v-model="searchText"
                            label="Search"
                            prepend-inner-icon="mdi-magnify"
                            class="search-input"
                            clearable
                            outlined
                            single-line
                        />
                    </div>

                    <div id="transactions" class="mt-6">
                        <template v-if="selectedAccount.transactions.length === 0">
                            There are no transactions for this account
                        </template>

                        <template v-else>
                            <v-data-table
                                :headers="headers"
                                :items="selectedAccount.transactions"
                                :items-per-page="5"
                                :search="searchText"
                            >
                                <template v-slot:item.amount="{ item }">
                                    <span :class="amountClass(item.amount)">{{ item.amount.toFixed(2) }}</span>
                                </template>
                            </v-data-table>
                        </template>
                    </div>
                </div>
            </div>

            <div id="right-col">
                <!--List of accounts-->
                <div class="rounded white shadow pa-8">
                    <h2 class="secondary--text">Accounts</h2>
                    <div class="d-flex justify-space-between">
                        Total Balance: ${{ totalBalance }}
                    </div>

                    <v-simple-table class="mt-6">
                        <tbody>
                        <tr
                            v-for="account of accounts"
                            :key="account.id"
                            class="account"
                            @click="selectedAccount = account"
                        >
                            <td class="py-3">
                                <div class="font-weight-medium">{{ account.nickname }}</div>
                                <div>xxxx-xxxx-xxxx-{{ account.id.slice(-4) }}</div>
                            </td>
                            <td class="py-3 text-right">
                                <div class="font-weight-medium">Balance</div>
                                <div>${{ account.balance }}</div>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>

                <!--Quick links-->
                <div class="rounded white shadow pa-8">
                    <h2 class="secondary--text">Quick Links</h2>
                    <ul class="pa-0">
                        <li v-for="link of quickLinks" :key="link.title" class="mb-2">
                            <router-link :to="{ name: link.route }">{{ link.title }}</router-link>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: 'Dashboard',

        data() {
            return {
                accounts: [
                    {
                        id: '6543-8885-3455-1224',
                        nickname: 'Checking',
                        balance: 853.97,
                        transactions: [
                            { id: 6, date: '10/03/2021', amount: -88.19, description: 'Smith\'s', balance: 853.97 },
                            { id: 5, date: '10/01/2021', amount: -75.00, description: 'Google', balance: 942.16 },
                            { id: 4, date: '10/01/2021', amount: -15.44, description: 'In-N-Out', balance: 1017.16 },
                            { id: 3, date: '10/01/2021', amount: -48.21, description: 'Chevron', balance: 1032.60 },
                            { id: 2, date: '09/30/2021', amount: 859.40, description: 'Paycheck', balance: 1080.81 },
                            { id: 1, date: '09/26/2021', amount: -34.59, description: 'Amazon', balance: 221.41 },
                            { id: 0, date: '09/19/2021', amount: -124.22, description: 'Costco', balance: 256.00 }
                        ]
                    },
                    {
                        id: '4895-9954-8812-2337',
                        nickname: 'Joint Savings',
                        balance: 4093.24,
                        transactions: []
                    }
                ],
                headers: [
                    { text: 'Date', value: 'date', sort: this.dateSort },
                    { text: 'Description', value: 'description' },
                    { text: 'Amount', value: 'amount', align: 'end' },
                    { text: 'Balance', value: 'balance', align: 'end' }
                ],
                searchText: '',
                selectedAccount: null,
                quickLinks: [
                    { title: 'Make a Payment', route: '' },
                    { title: 'Statements', route: '' },
                    { title: 'FAQ', route: '' },
                    { title: '', route: '' }
                ]
            };
        },

        computed: {
            totalBalance() {
                return this.accounts.reduce((a, b) => a.balance + b.balance);
            }
        },

        methods: {
            amountClass(amount) {
                return {
                    'green--text': amount > 0,
                    'red--text': amount < 0
                };
            },

            // TODO - This only handles sorting in descending order
            dateSort(a, b) {
                return this.formatDate(a) - this.formatDate(b);
            },

            formatDate(date) {
                const [month, day, year] = date.split('/');
                return `${year}/${month}/${day}`;
            }
        }
    };
</script>

<style lang="scss" scoped>
    ul{
        list-style: none;
    }

    li > a {
        text-decoration: none;
    }

    #dashboard-grid {
        display: grid;
        grid-template-columns: 3fr 2fr;
        grid-template-areas: "a b";
        grid-gap: 1rem;
    }

    #left-col,
    #right-col {
        display: flex;
        flex-direction: column;
        grid-gap: 1rem;
    }

    #left-col {
        grid-area: a;
    }

    #right-col {
        grid-area: b;
    }

    .account:hover {
        cursor: pointer;
    }

    .search-input {
        margin-left: 1rem;
        max-width: 350px;
    }

    /* Media queries */
    @media (max-width: 768px) {
        #dashboard-grid {
            grid-template-columns: 1fr;
            grid-template-areas: "b"
                                 "a";
        }
    }
</style>
