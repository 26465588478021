<template>
    <div class="rounded white shadow pa-10 mt-6">
        <v-row align="center" class="mb-10">
            <v-col cols="9">
                <h1>Your new favorite bank.</h1>
                <h2 class="text-primary">Available 24/7.</h2>
            </v-col>
            <v-col>
                <v-btn depressed x-large class="bg-orange" @click="$router.push({ name: 'Register' })">Open an account</v-btn>
            </v-col>
        </v-row>
        <v-row justify="space-between">
            <v-col v-for="option of options" :key="option.title" class="border cursor-pointer" @click="onOptionClick(option.link)">
                <div class="material-symbols-outlined">{{ option.icon }}</div>
                <div class="mt-5">{{ option.title }}</div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        name: 'LandingPage',

        data() {
            return {
                options: [
                    { title: 'Checking & Savings', icon: 'savings', link: process.env.VUE_APP_ONLINE_BANKING_URL },
                    { title: 'Home Loan', icon: 'cottage', link: process.env.VUE_APP_HOME_LOAN_URL },
                    { title: 'Financial Education', icon: 'school', link: process.env.VUE_APP_EDUCATION_URL }
                ]
            };
        },

        methods: {
            onOptionClick(link) {
                window.location.href = link;
            }
        }
    };
</script>

<style lang="scss" scoped>
    .material-symbols-outlined {
        font-variation-settings:
            'FILL' 0,
            'wght' 100,
            'GRAD' 0,
            'opsz' 48;
        font-size: 96px;
    }

    h1 {
        color: $secondary;
        font-size: 4rem;
    }

    h2{
        color: $primary;
        font-size: 3rem;
    }

    .bg-orange {
        background: #ef6c00 !important;
        color: white;
    }

    .col.border {
        border: 1px solid $gray-10;
        border-radius: 4px;
        padding: 2rem;
        text-align: center;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .drop-shadow {
        --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }

    .row {
        grid-gap: 1rem;
    }

    .v-icon {
        color: $primary;
        font-size: 100px;
    }
</style>
