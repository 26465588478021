
    import Vue from 'vue';
    import AppHeader from '@/components/AppHeader.vue';
    import AppFooter from '@/components/AppFooter.vue';
    import Notifications from '@/components/Notifications.vue';

    export default Vue.extend({
        name: 'App',

        components: {
            AppHeader,
            AppFooter,
            Notifications
        }
    });
