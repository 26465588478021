<template>
    <content-wrapper>
        <h1 class="text-h4">Admin Settings</h1>

        <v-switch
            v-model="oauthEnabled"
            label="Single Sign-On"
            hide-details
        />
    </content-wrapper>
</template>

<script>
    export default {
        name: 'Admin',

        data() {
            return {
                oauthEnabled: false
            };
        },

        watch: {
            oauthEnabled(val) {
                document.cookie = `oauth=${val.toString()}; domain=trivir.com; SameSite=Lax`;
            }
        },

        created() {
            this.oauthEnabled = document.cookie.includes('oauth=true');
        }
    };
</script>

<style scoped>

</style>
