import { CallbackType, FRCallback } from '@forgerock/javascript-sdk';
import { Service } from '@/services/service';
import jwtDecode from 'jwt-decode';

class ForgeRockAMUtilService {
    public static getCallbacksOfType<T extends FRCallback>(callbacks: FRCallback[], type: CallbackType): T[] {
        return callbacks.filter(callback => callback.getType() === type) as T[];
    }

    public static getCallbackOfType<T extends FRCallback>(callbacks: FRCallback[], type: CallbackType): T {
        const callbacksOfType = this.getCallbacksOfType<T>(callbacks, type);
        if (callbacksOfType.length !== 1) {
            throw new Error(`Expected 1 callback of type "${type}", but found ${callbacksOfType.length}`);
        }
        return callbacksOfType[0];
    }

    // Takes a realm path returned by AM's '/json/serverinfo' endpoint and converts it into an extended realm path for '/json/realm/*' endpoints
    public static getRealmUrlPathFromRealmPath(amRealmPath: string): string {
        return amRealmPath === '/' ? '/realms/root' : `/realms/root/realms${amRealmPath}`;
    }

    public static isKbaQuestionGroupsCallback(callback: FRCallback): boolean {
        return callback.getType() === CallbackType.HiddenValueCallback &&
            callback.getOutputByName('id', undefined) === 'questionGroups';
    }

    public static isRSADeviceFingerprintCallback(callback: FRCallback): boolean {
        return callback.getType() === CallbackType.HiddenValueCallback &&
            callback.getOutputByName('id', undefined) === 'devReqFP';
    }

    public static isStageHiddenValueCallback(callback: FRCallback): boolean {
        return callback.getType() === CallbackType.HiddenValueCallback &&
            callback.getOutputByName('id', undefined) === 'stage' &&
            typeof callback.getOutputValue('value') === 'string';
    }
}

class OAuthService {
    private static amRealmPath = '/bravo'; // TODO - Hardcoded realm
    private static authorizationEndpoint = `${process.env.VUE_APP_AM_HOST}/${process.env.VUE_APP_AM_DEPLOY_CONTEXT}/oauth2/realms/root/realms${this.amRealmPath}/authorize`;
    private static clientId = 'banking-app';
    private static redirectUri = `${process.env.VUE_APP_ONLINE_BANKING_URL}`;
    private static requestedScopes = 'profile openid';
    private static tokenEndpoint = `${process.env.VUE_APP_AM_HOST}/${process.env.VUE_APP_AM_DEPLOY_CONTEXT}/oauth2/realms/root/realms${this.amRealmPath}/access_token`;

    // Generate a secure random string using the browser crypto functions
    private static generateRandomString(): string {
        const array: Uint32Array = new Uint32Array(28);
        window.crypto.getRandomValues(array);
        return Array.from(array, dec => ('0' + dec.toString(16)).substr(-2)).join('');
    }

    // Calculate the SHA256 hash of the input text.
    // Returns a promise that resolves to an ArrayBuffer
    private static sha256(plain: string): Promise<ArrayBuffer> {
        const encoder: TextEncoder = new TextEncoder();
        const data: Uint8Array = encoder.encode(plain);
        return window.crypto.subtle.digest('SHA-256', data);
    }

    // Base64-urlencodes the input string
    private static base64urlencode(str: string): string {
        // Convert the ArrayBuffer to string using Uint8 array to conver to what btoa accepts.
        // btoa accepts chars only within ascii 0-255 and base64 encodes them.
        // Then convert the base64 encoded to base64url encoded
        //   (replace + with -, replace / with _, trim trailing =)
        // @ts-ignore
        return btoa(String.fromCharCode.apply(null, new Uint8Array(str)))
            .replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    }

    // Return the base64-urlencoded sha256 hash for the PKCE challenge
    private static async pkceChallengeFromVerifier(v: string): Promise<string> {
        if (!window.crypto.subtle) {
            if (window.location.hostname !== 'localhost' && window.location.protocol !== 'https:') {
                throw new Error('Unable to generate verifier. This page needs to be loaded using https.');
            } else {
                throw new Error("Unable to generate verifier. This browser doesn't provide crypto.subtle.");
            }
        }

        const hashed: ArrayBuffer = await this.sha256(v);
        // @ts-ignore
        return this.base64urlencode(hashed);
    }

    public static async login(): Promise<void> {
        localStorage.setItem('client_id', this.clientId);
        localStorage.setItem('authorization_endpoint', this.authorizationEndpoint);
        localStorage.setItem('token_endpoint', this.tokenEndpoint);

        // Create and store a random "state" value
        const state = this.generateRandomString();
        localStorage.setItem('pkce_state', state);

        // Create and store a new PKCE codeVerifier (the plaintext random secret)
        const codeVerifier = this.generateRandomString();
        localStorage.setItem('pkce_code_verifier', codeVerifier);

        // Hash and base64-urlencode the secret to use as the challenge
        let codeChallenge;
        try {
            codeChallenge = await this.pkceChallengeFromVerifier(codeVerifier);
        } catch (err: any) {
            console.error(err);
            return;
        }

        // Build the authorization URL
        const url = this.authorizationEndpoint +
            '?realm=' + this.amRealmPath +
            '&client_id=' + encodeURIComponent(this.clientId) +
            '&response_type=code' +
            '&scope=' + encodeURIComponent(this.requestedScopes) +
            '&redirect_uri=' + encodeURIComponent(this.redirectUri) +
            '&code_challenge=' + encodeURIComponent(codeChallenge) +
            '&code_challenge_method=S256' +
            '&state=' + encodeURIComponent(state)
        ;

        // Redirect to the authorization server
        window.location.href = url;
    }

    public static async getIdToken(code: string, state: string) {
        document.cookie = 'oauth=true; domain=trivir.com; SameSite=Lax';

        if (!localStorage.getItem('pkce_code_verifier')) {
            history.replaceState(null, '', window.location.origin + window.location.pathname);
            return;
        }

        // Verify state matches what we set at the beginning
        if (localStorage.getItem('pkce_state') !== state) {
            console.error('"state" doesn\'t match what was expected');
        } else {
            // Exchange the authorization code for an access token
            const params: Record<string, any> = {
                grant_type: 'authorization_code',
                code: code,
                client_id: this.clientId,
                redirect_uri: this.redirectUri,
                code_verifier: localStorage.getItem('pkce_code_verifier')
            };

            try {
                const response = await fetch(this.tokenEndpoint, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    },
                    body: Object.keys(params).map(key => key + '=' + params[key]).join('&')
                });

                const data = await response.json();

                if (data.id_token) {
                    return data.id_token;
                } else {
                    return null;
                }
            } catch (error) {
                console.error(error);
            }
        }

        // Clean these up since we don't need them anymore
        localStorage.removeItem('pkce_state');
        localStorage.removeItem('pkce_code_verifier');
    }

    public static isUserTypeMember(token: string) {
        const claims: Record<string, any> = jwtDecode(token);
        return claims.user_type === 'member';
    }
}

class UtilService extends Service {
    public static am = ForgeRockAMUtilService;
    public static oauth = OAuthService;

    public static constants = Object.freeze({
        PASSWORD_ALLOWED_CHARACTERS_REGEX: /^[a-zA-Z0-9!#$&"(),./:?@'-]*$/,
        PASSWORD_MIN_LENGTH: 8,
        PASSWORD_MAX_LENGTH: 32,
        PASSWORD_ONE_LETTER_REGEX: /^.*[a-zA-Z]+.*$/,
        PASSWORD_ONE_NUMBER_REGEX: /^.*[0-9]+.*$/,
        KBA_ANSWERS_REGEX: /^[a-zA-Z0-9 ]*$/
    });

    public static camelOrPascalCaseToKebabCase(str: string): string {
        // The following can't be used yet since iOS/Safari don't support regex look behind assertions
        // return str
        //     .replace(/\B(?:([A-Z])(?=[a-z]))|(?:(?<=[a-z0-9])([A-Z]))/g, '-$1$2')
        //     .toLowerCase();
        // So we're using this alternate implementation instead (until the above works in most browsers)
        return str
            .replace(/\B([A-Z])(?=[a-z])/g, '-$1')
            .replace(/\B([a-z0-9])([A-Z])/g, '$1-$2')
            .toLowerCase();
    }

    public static getFormattedPhone(unformattedPhone: string): string {
        if (unformattedPhone.length !== 10) {
            console.error('getFormattedPhone() function expects a 10-digit number');
            return unformattedPhone;
        }

        return `(${unformattedPhone.substring(0, 3)}) ${unformattedPhone.substring(3, 6)}-${unformattedPhone.substring(6, 10)}`;
    }

    public static getUnformattedPhone(formattedPhoneNumber: string): string {
        const result = formattedPhoneNumber.match(/\d/g);
        return result ? result.join('') : '';
    }

    public static localeMessageDefined(localeMessagePath: string): boolean {
        return this.$vue.$t(localeMessagePath) !== localeMessagePath;
    }
}

export default UtilService;
